// src/index.tsx
import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {createHashRouter, RouterProvider} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import RecipeList from "./components/recipe-list";
import RecipePage from "./components/recipe-page";
import {Lang} from "./types/types";
import Layout from "./components/layout";
import {RecipeProvider} from "./context/recipe-context";
import ScrollToTop from "./util/scroll-to-top";
import Debugger from "./components/debugger";

const ScrollWrapper = ({element}: { element: React.ReactNode }) => (
  <>
    <ScrollToTop/>
    {element}
  </>
);

const App: React.FC = () => {
  const [language, setLanguage] = useState<Lang>(() => {
    // If not found in the query, check hash
    const hashParams = new URLSearchParams(window.location.hash.split('?')[1]);
    if(hashParams.get('l')) return hashParams.get('l') as Lang;
    // Fallback to ls:
    return localStorage.getItem('selectedLanguage') as Lang || Lang.HE;
  });

  useEffect(() => {
    localStorage.setItem('selectedLanguage', language);
  }, [language]);

  const router = createHashRouter([
    {path: "/", element: <ScrollWrapper element={<RecipeList language={language}/>}/>},
    {path: "/debug", element: <ScrollWrapper element={<Debugger language={language}/>}/>},
    {path: "/:recipeId", element: <ScrollWrapper element={<RecipePage language={language}/>}/>},
  ]);

  return (
    <RecipeProvider language={language}>
      <Layout language={language} onLanguageChange={setLanguage}>
        <RouterProvider router={router}/>
      </Layout>
    </RecipeProvider>
  );
};

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );

  reportWebVitals();
}
