import React, { useState, useEffect, useRef } from 'react';
import { RecipeType } from '../../types/types';

const DynamicGrid: React.FC<{ recipeContent: RecipeType }> = ({ recipeContent }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [columnCount, setColumnCount] = useState(1);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const updateLayout = () => {
      if (containerRef.current) {
        // Measure container width
        const containerWidth = containerRef.current.offsetWidth;

        // Find the widest element width
        const items = containerRef.current.querySelectorAll<HTMLLIElement>('li');
        const maxItemWidth = Array.from(items).reduce((maxWidth, item) => {
          return Math.max(maxWidth, item.offsetWidth);
        }, 0);

        setMaxWidth(maxItemWidth);

        // Calculate potential number of columns
        let newColumnCount = maxItemWidth > 0 ? Math.floor((containerWidth * 0.9) / maxItemWidth) : 1;
        // Ensure a maximum of 4 columns
        newColumnCount = Math.min(newColumnCount, 4);

        // Ensure at least 4 items per column if possible
        const totalItems = items.length;
        while (newColumnCount > 1 && totalItems / newColumnCount < 4) {
          newColumnCount -= 1;
        }

        setColumnCount(newColumnCount || 1); // Ensure at least 1 column
      }
    };

    updateLayout(); // Initial call to set layout

    window.addEventListener('resize', updateLayout); // Update on window resize
    return () => window.removeEventListener('resize', updateLayout); // Cleanup listener
  }, [recipeContent]);

  return (
    <div className="grid gap-y-3" ref={containerRef}>
      {Object.entries(recipeContent.ingredients).map(([section, ingredients]) => {
        const columnItems = Math.ceil(ingredients.length / columnCount);
        return (
          <React.Fragment key={section}>
            {section !== '$main' && (
              <h1 className="font-semibold capitalize">{section.replaceAll('_', ' ')}</h1>
            )}
            <ul
              className="grid gap-x-2 p-0"
              style={{ gridTemplateColumns: `repeat(${columnCount}, minmax(0, max-content))` }}
            >
              {ingredients.map((ingredient, index) => {
                const gridRowStart = (index % columnItems) + 1;
                const gridColumnStart = Math.floor(index / columnItems) + 1;
                return (
                  <li
                    key={index}
                    className="md:w-max break-inside-avoid list-disc pl-2 pr-2 whitespace-pre-wrap capitalize"
                    style={{ gridRowStart, gridColumnStart, minWidth: maxWidth }}
                  >
                    {ingredient}
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DynamicGrid;
