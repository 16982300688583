import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Lang, RecipeFileType} from "../types/types";
import clsx from "clsx";
import Loader from "./loader";
import {useRecipeContext} from "../context/recipe-context";

type RecipeListProps = {
  language: Lang;
};

const Debugger: React.FC<RecipeListProps> = ({language}) => {
  const {recipes, categories, loading} = useRecipeContext();
  const [missing, setMissing] = useState<string[]>([]);

  useEffect(() => {
    fetch(`/config/missing-recipes.json`)
      .then(response => response.json())
      .then(setMissing)
  }, []);

  const badHebrewTitles = (r: RecipeFileType) => {
    const titles = [
      ...Object.keys(r.he.ingredients),
      ...Object.keys(r.he.directions)
    ];

    return titles.some(title => !title.startsWith("$") && !/[\u0590-\u05FF]/.test(title));
  }

  const blocks = [
    {en: "English keys for Hebrew Titles", he: "כותרות אנגלית בחלק העברי", items: recipes.filter(badHebrewTitles)},
    {en: "Title with 's", he: "כותרת עם שייכות שגויה (באנגלית)", items: recipes.filter(r => r.en.title.match(/^[a-zA-Z]+'s /))},
    {en: "Bad Category", he: "קטגוריה שגויה", items: recipes.filter(r => r.category === "uncategorized")},
    {en: "Missing Image", he: "תמונות חסרות", items: recipes.filter(r => !r.images?.[0])},
    {en: "Missing Servings", he: "חסר מס' מנות", items: recipes.filter(r => !r.en.servings || !r.he.servings)},
    {en: "Missing Preptime", he: "חסר זמן הכנה", items: recipes.filter(r => !r.en.preptime || !r.he.preptime)},
    {en: "Missing Cooktime", he: "חסר זמן בישול", items: recipes.filter(r => !r.en.cooktime || !r.he.cooktime)},
  ];

  const totalRecipes = recipes.length;
  const totalMissing = missing.length;
  const totalIssues = blocks.reduce((sum, block) => sum + block.items.length, 0);

  if (loading) return <Loader className="m-12"/>;

  return (
    <div className={clsx("grid gap-4 w-full py-2 border-b pb-8", language === Lang.HE && "rtl text-right")}>

      <div className="grid gap-4 items-center px-4 py-1 md:grid-flow-col md:gap-8 rounded-md">
        <div className="text-center border-2 border-dotted border-gray-300 py-3">
          <h2 className="text-xl font-semibold">{language === Lang.EN ? "Total Recipes" : "סה״כ מתכונים"}</h2>
          <p className="text-2xl">{totalRecipes}</p>
        </div>
        <div className="text-center border-2 border-dotted border-gray-300 py-3">
          <h2 className="text-xl font-semibold">{language === Lang.EN ? "Missing Recipes" : "מתכונים חסרים"}</h2>
          <p className="text-2xl">{totalMissing}</p>
        </div>
        <div className="text-center border-2 border-dotted border-gray-300 py-3">
          <h2 className="text-xl font-semibold">{language === Lang.EN ? "Total Issues" : "סה״כ תקלות"}</h2>
          <p className="text-2xl">{totalIssues}</p>
        </div>
      </div>

      {/* Rest of the component */}
      <div className="grid gap-4 items-center px-4 md:grid-flow-col md:gap-8">
        <h1 className="text-2xl md:text-3xl font-semibold">{language === Lang.EN ? "Recipes w/ Problems" : "מתכונים עם תקלות"}</h1>
        <div id="logo" className="relative z-20 justify-self-end order-first md:order-none overflow-y-visible max-h-2 md:max-h-20">
          <img src="/icons/spill.svg" alt="Logo" className={clsx("h-32 md:h-36", language === Lang.HE && "-scale-x-100")}/>
        </div>
      </div>

      {missing.length > 0 && <div className={clsx("min-w-60 px-2 md:px-4", language === Lang.HE && "rtl text-right")}>
        <div className="grid grid-flow-col grid-cols-[max-content_1fr] gap-0 items-center py-1 relative z-10">
          <h1 className="text-lg md:text-xl font-semibold">{language === Lang.EN ? "Missing Recipes" : "מתכונים חסרים"} ({missing.length})</h1>
          <div className="flex-grow border-b-2 border-dotted border-gray-300 ml-4 mt-1"></div>
        </div>
        <ul className="grid gap-x-2 sm:grid-cols-2 lg:grid-cols-3 p-0">
          {missing.map((recipeTitle, index) => (
            <li key={index} className="break-inside-avoid pl-2 pr-2 whitespace-pre-wrap list-disc">
              {recipeTitle}
            </li>
          ))}
        </ul>
      </div>}
      {blocks.map((block) => (block.items.length > 0 ?
          <div key={block.en} className={clsx("min-w-60 px-2 md:px-4", language === Lang.HE && "rtl text-right")}>
            <div className="grid grid-flow-col grid-cols-[max-content_1fr] gap-0 items-center py-1 relative z-10">
              <h1 className="text-lg md:text-xl font-semibold">{block[language]} ({block.items.length})</h1>
              <div className="flex-grow border-b-2 border-dotted border-gray-300 ml-4 mt-1"></div>
            </div>
            <ul className="grid gap-x-2 sm:grid-cols-2 lg:grid-cols-3 p-0">
              {block.items.map((recipe, index) => (
                <li key={index} className="break-inside-avoid pl-2 pr-2 whitespace-pre-wrap list-disc">
                  <Link to={`/${recipe.id}`}>{recipe[language].title}</Link>
                </li>
              ))}
            </ul>
          </div> : null
      ))}
    </div>
  );
};

export default Debugger;
