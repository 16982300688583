import React from 'react';
import {Lang} from '../types/types';
import clsx from "clsx";

type LayoutProps = {
    children: React.ReactNode;
    language: Lang;
    onLanguageChange: (language: Lang) => void;
};

const getCurrentYear = () => new Date().getFullYear();

const languageOptions: { [key in Lang]: string } = {
    en: "English",
    he: "Hebrew"
};

const Layout: React.FC<LayoutProps> = ({children, language, onLanguageChange}) => {
    return (
        <div className="layout md:py-4">
            <main id="wrapper" className={clsx(
                "flex flex-col items-center justify-center",
                "max-w-screen md:max-w-[1000px] md:rounded-2xl md:rounded-bl-none",
                "m-auto py-5 pl-9 pr-4 md:py-0 md:pr-8 md:pl-20",
                "bg-[url('../public/recipe_bg.png')] bg-contain",
                "md:shadow-[8px_5px_10px_-0px_rgba(0,0,0,0.15)]",
            )}>
                <header
                    className="grid grid-flow-col grid-cols-[1fr_max-content_1fr] items-center content-center text-center py-6 border-b w-full">
                    <div className="grid justify-end"></div>
                    <a href="/">
                        <h1 className="text-3xl md:text-4xl font-title font-bold justify-center">
                            - Perez Cookbook -
                        </h1>

                        {/*<div className="flex flex-col items-center">
                            <svg className="w-full max-w-md" viewBox="0 0 500 300">
                                 Curve path for the text
                                <path
                                    id="curve"
                                    d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97"
                                    fill="transparent"
                                />
                                 Main title text
                                <text
                                    fontSize="40" // Increased text size
                                    fontWeight="bolder" // Increased text weight
                                    className="font-title"
                                >
                                    <textPath href="#curve" startOffset="50%" textAnchor="middle">
                                        - Perez Cookbook -
                                    </textPath>
                                </text>
                                 Additional text "anno 1964"
                                <text
                                    x="50%"  // Positioning at the center
                                    y="220"  // Position below the curved text, adjust as needed
                                    fontSize="24" // Increased text size for "anno 1964"
                                    fontWeight="bolder" // Increased text weight
                                    textAnchor="middle"
                                    className="font-title"
                                >
                                    anno 1964
                                </text>
                            </svg>
                        </div>*/}


                    </a>
                    <div className="grid justify-start"></div>
                </header>
                {children}
                <footer className="flex justify-center items-center text-center py-6 gap-4">
                    <div id="lang-switcher" className="flex gap-2">
                        {Object.values(Lang).filter(l => l !== language).map((lang) => (
                            <button key={lang} onClick={() => onLanguageChange(lang)}>
                                {languageOptions[lang]}
                            </button>
                        ))}
                    </div>
                    <p>|</p>
                    <div>
                        <p>© 1946-{getCurrentYear()} Perez Family</p>
                    </div>
                </footer>
            </main>
        </div>
    );
};

export default Layout;
