import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import Loader from "./loader";
import {useRecipeContext} from "../context/recipe-context";
import {HeaderIcons, Lang, RecipeType} from "../types/types";
import {IoMdArrowRoundBack, IoMdArrowRoundForward} from "react-icons/io";
import {IoChevronBack, IoChevronForward} from "react-icons/io5";
import {HiOutlineExternalLink} from "react-icons/hi";
import DynamicGrid from "./ui/dynamic-grid";
import ModalComp from "./ui/modal";

type RecipePageProps = {
  language: Lang;
};

const RecipePage: React.FC<RecipePageProps> = ({language}) => {
  const {recipeId} = useParams<{ recipeId: string }>();
  const navigate = useNavigate();
  const {
    recipes,
    loading,
    currentRecipeIndex,
    setCurrentRecipeIndex,
    getNextRecipe,
    getPreviousRecipe
  } = useRecipeContext();
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [isImageTransitioning, setIsImageTransitioning] = useState<boolean>(false);
  const [isPageTransitioning, setIsPageTransitioning] = useState<boolean>(false);
  const [galleryOpen, setGalleryOpen] = useState<boolean>(false);

  useEffect(() => {
    const index = recipes.findIndex((r) => r.id === recipeId);
    setCurrentRecipeIndex(index);
  }, [recipeId, recipes, setCurrentRecipeIndex]);

  const recipe = recipes[currentRecipeIndex];
  if (!recipe) return <Loader className="m-12"/>;

  const nextRecipe = getNextRecipe();
  const previousRecipe = getPreviousRecipe();

  const nextImage = () => {
    setIsImageTransitioning(true);
    setTimeout(() => {
      setImageIndex((prevIndex) =>
        recipe.images[prevIndex + 1] ? prevIndex + 1 : 0
      );
      setIsImageTransitioning(false);
    }, 300); // duration of the fade transition
  };

  const prevImage = () => {
    setIsImageTransitioning(true);
    setTimeout(() => {
      setImageIndex((prevIndex) =>
        recipe.images[prevIndex - 1] ? prevIndex - 1 : recipe.images.length - 1
      );
      setIsImageTransitioning(false);
    }, 300); // duration of the fade transition
  };


  const handleNext = () => {
    if (nextRecipe) {
      setIsPageTransitioning(true); // Start the transition
      setTimeout(() => {
        navigate(`/${nextRecipe.id}`);
        setIsPageTransitioning(false); // Reset the transition after navigation
      }, 300); // Tailwind transition duration
    }
  };

  const handlePrevious = () => {
    if (previousRecipe) {
      setIsPageTransitioning(true); // Start the transition
      setTimeout(() => {
        navigate(`/${previousRecipe.id}`);
        setIsPageTransitioning(false); // Reset the transition after navigation
      }, 300); // Tailwind transition duration
    }
  };


  const recipeContent = recipe[language];
  const sectionNumbers = Object.entries(recipeContent.directions).reduce<Record<string, any>>((acc, [key, values]) => {
    acc[key] = acc["-C-"];
    acc["-C-"] += values.length;
    return acc;
  }, {"-C-": 1})
  const isEN = language === Lang.EN;
  const dropOwnerRegex = new RegExp(` ${isEN ? "by" : "של"} .*$`);

  if (loading) return <Loader className="m-12"/>;

  const headerIcons: HeaderIcons = ["preptime", "cooktime", "servings"];

  return (
    <div className={clsx(
      "grid grid-flow-row w-full gap-8",
      language === Lang.HE && "rtl text-right",
      isPageTransitioning ? "opacity-0 transition-opacity duration-300 ease-in-out" : "opacity-100"
    )}>
      {recipe.images?.length > 0 && <ModalComp recipe={recipe} language={language} show={galleryOpen} setOpen={setGalleryOpen} imageIndex={imageIndex} setImageIndex={setImageIndex} />}
      <div
        className={clsx("grid pt-6 pb-4 md:py-8 border-b", recipe.images?.length > 0 ? "md:grid-cols-[2fr_1fr]" : "justify-items-center")}>
        <div
          className={clsx("grid gap-1 items-center content-center text-center text-balance", !recipe.images?.length && "md:w-full")}>
          <h1 className="font-bold text-3xl md:text-4xl">{recipeContent.title}</h1>
          <h1 className="font-semibold text-gray-500 text-lg md:text-xl">{recipeContent.subtitle}</h1>
          {recipeContent.credit && <h1 className="font-semibold text-gray-500 text-lg md:text-xs">{isEN ? "Adapted from an original recipe by" : "מבוסס על מתכון מאת"} {recipeContent.credit}</h1>}

          <div
            className="flex flex-wrap items-center justify-center justify-self-center gap-x-6 max-w-[70%]">
            {headerIcons.map((recipeKey) => (
              recipe[language][recipeKey] ? (
                <div key={recipeKey}
                     className="grid grid-flow-col gap-1 w-max items-center grid-cols-[max-content_max-content]">
                  <img src={`/icons/${recipeKey}.svg`} className="h-8" alt={recipeKey}/>
                  <span>{recipe[language][recipeKey]}</span>
                </div>
              ) : null
            ))}
          </div>

        </div>
        {recipe.images?.length > 0 && (
          <div className="md:max-h-1">
            <div className={clsx(
              "grid gap-2",
              "bg-white p-3 shadow-xl translate-y-[5%] md:-translate-y-[15%]",
              "max-w-[80%] md:max-w-full h-auto mx-auto",
              language === Lang.HE ? "-rotate-[6deg]" : "rotate-[6deg]"
            )}>
              <img
                role="button" onClick={() => setGalleryOpen(true)}
                src={`/images/${recipe.images[imageIndex]}`}
                alt="Recipe Image"
                loading="lazy"
                className={clsx(
                  "transition-opacity duration-300 ease-in-out",
                  isImageTransitioning ? "opacity-0" : "opacity-100"
                )}
                onError={(e) => {
                  const imgElement = e.target as HTMLImageElement;
                  imgElement.closest('div')!.style.display = 'none';
                }}
              />
              <div
                className={clsx("grid grid-cols-[max-content_1fr_max-content] w-full text-gray-500", recipe.images?.length < 2 && "invisible")}
                style={{direction: "ltr"}}>
                <button onClick={prevImage}><IoChevronBack className="h-6 w-6"/></button>
                <span/>
                <button className="" onClick={nextImage}><IoChevronForward className="h-6 w-6"/>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {recipeContent.preface && <div id="preface" className="whitespace-pre-wrap border-b pb-8">
        {recipeContent.preface}
      </div>}

      <div id="ingredients" className="grid grid-flow-row w-full gap-3">
        <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-center gap-4">
          <div>
            <img src="/icons/ingredients.svg"
                 className={clsx("h-12", language === Lang.HE && "-scale-x-100")} alt="ingredients"/>
          </div>
          <h3 className="font-semibold text-xl">{language === Lang.EN ? `Ingredients` : `מרכיבים`}:</h3>
        </div>

        <DynamicGrid recipeContent={recipeContent}/>

      </div>

      <div id="steps" className="grid grid-flow-row w-full gap-3">
        <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-center gap-4">
          <div>
            <img src="/icons/prep.svg"
                 className={clsx("h-12", language === Lang.HE && "-scale-x-100")} alt="ingredients"/>
          </div>
          <h3 className="font-semibold text-xl">{language === Lang.EN ? `Preparation:` : `הכנה:`}</h3>
        </div>
        {Object.entries(recipeContent.directions).map(([section, steps]) => (
          <React.Fragment key={section}>
            {section !== "$main" && <h1 className="font-semibold capitalize">{section.replaceAll("_", " ")}</h1>}
            <ol className="grid gap-y-1 sm:gap-y-0 list-decimal" start={sectionNumbers[section]}>
              {steps.map((step, index) => (
                <li key={index} className="list-decimal">{step}</li>
              ))}
            </ol>
          </React.Fragment>))}
      </div>

      {recipeContent.notes && recipeContent.notes.length &&
        <div id="notes" className="grid grid-flow-row w-full gap-3">
          <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-center gap-4">
            <div>
              <img src="/icons/notes.svg"
                   className={clsx("h-12", language === Lang.HE && "-scale-x-100")} alt="ingredients"/>
            </div>
            <h3 className="font-semibold text-xl">{language === Lang.EN ? `Notes:` : `הערות ותוספות:`}</h3>
          </div>
          <ul className="grid gap-y-1 sm:gap-y-0">
            {recipeContent.notes.map((note, index) => (
              <li key={index} className="list-disc">{note}</li>
            ))}
          </ul>
        </div>
      }
      {recipe.references && recipe.references.length &&
        <div id="notes" className="grid grid-flow-row w-full gap-3">
          <div className="grid grid-flow-col grid-cols-[max-content_1fr] items-center gap-4">
            <div>
              <img src="/icons/external.svg"
                   className={clsx("h-12", language === Lang.HE && "-scale-x-100")} alt="ingredients"/>
            </div>
            <h3 className="font-semibold text-xl">{language === Lang.EN ? `See also:` : `ראו גם:`}</h3>
          </div>
          <ul>
            {recipe.references.map((reference, index) => (
              <li key={index} className="list-disc">
                <a href={reference.url} target="_blank"
                   className="flex gap-x-2 underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                  {reference[language]} <HiOutlineExternalLink className="translate-y-1"/>
                </a>
              </li>
            ))}

          </ul>
        </div>
      }
      <div className="grid grid-flow-col w-full grid-cols-[1fr_max-content_1fr] border-t pt-6 gap-6 text-balance">
        <div className="grid justify-start">
          {previousRecipe && (
            <button onClick={handlePrevious} className="grid grid-flow-col items-center gap-2 leading-none">
              {isEN ? <IoMdArrowRoundBack/> : <IoMdArrowRoundForward/>}
              <p className={clsx(!isEN && "-mt-0.5")}>{previousRecipe[language].title.replace(dropOwnerRegex, "")}</p>
            </button>
          )}
        </div>

        <div className="grid justify-center">
          <button onClick={() => navigate("/")}>
            {language === Lang.EN ? "Back to index" : "חזרה לתוכן העניינים"}
          </button>
        </div>
        <div className="grid justify-end">
          {nextRecipe && (
            <button onClick={handleNext} className="grid grid-flow-col items-center gap-2">
              <p className={clsx(!isEN && "-mt-0.5")}>{nextRecipe[language].title.replace(dropOwnerRegex, "")}</p>
              {isEN ? <IoMdArrowRoundForward/> : <IoMdArrowRoundBack/>}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipePage;
