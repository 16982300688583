
export enum Lang {
    EN = 'en',
    HE = 'he',
}

export type RecipeFileType = {
    id: string;
    images: string[];
    category: string;
    references?: ({[key in Lang]: string} & {url: string})[]
} & {
    [key in Lang]: RecipeType;
}

export type RecipeType = {
    title: string;
    subtitle: string;
    credit?: string;
    servings: string;
    preptime: string;
    cooktime: string;
    preface: string;
    ingredients: {
        [key: string]: string[];
    };
    directions: {
        [key: string]: string[];
    };
    notes?: string[];
};

export type CategoryType = {
    id: string;
} & {
    [key in Lang]: string;
}

export type HeaderIcons = (keyof Pick<RecipeType, 'servings' | 'preptime' | 'cooktime'>)[];

