import React from 'react';
import {Lang, RecipeFileType} from "../../types/types";
import {Modal} from "flowbite-react";

type ModalProps = React.FC<{ recipe: RecipeFileType, language: Lang, show: boolean, setOpen: (isOpen: boolean) => void, imageIndex: number, setImageIndex: (newIndex: number) => void }>

const ModalComp: ModalProps = ({recipe, language, show, setOpen, imageIndex, setImageIndex}) => {
  return (
    <Modal show={show} onClose={() => setOpen(false)} size="4xl" dismissible popup>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg" src={`/images/${recipe.images[imageIndex]}`} alt=""/>
          </div>
          {recipe.images?.length > 1 && <div className="grid grid-cols-[repeat(auto-fill,minmax(40%,1fr))] md:grid-cols-[repeat(auto-fill,minmax(20%,1fr))] gap-4 ">
            {recipe.images.map((image, index) => (
              <div key={index}
                   role="button" onClick={() => setImageIndex(index)}
                   className={`flex h-32 overflow-hidden content-center items-center rounded-lg cursor-pointer
                   ${imageIndex === index ? 'shadow-lg' : ''}
                   hover:scale-105 transition-transform duration-200 ease-in-out hover:shadow-md`}
              >
                <img className="object-cover h-full w-full" src={`/images/${image}`} alt=""/>
              </div>
            ))}
          </div>}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalComp;
