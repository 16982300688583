import yaml from 'js-yaml';
import { CategoryType, RecipeFileType } from "../types/types";

const getCacheBustingParam = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    return `${year}-${month}-${day}-${hour}`;
};

const loadRecipeFilenames = async (): Promise<string[]> => {
    try {
        const cacheBustingParam = getCacheBustingParam();
        const response = await fetch(`${process.env.PUBLIC_URL}/recipes/recipes-list.json?cb=${cacheBustingParam}`);
        if (!response.ok) throw new Error('Failed to fetch recipes-list.json');
        return await response.json();
    } catch (error) {
        console.error('Error loading recipes list:', error);
        return [];
    }
};

export const loadRecipe = async (filename: string): Promise<RecipeFileType | null> => {
    try {
        const cacheBustingParam = getCacheBustingParam();
        const response = await fetch(`${process.env.PUBLIC_URL}/recipes/${filename}?cb=${cacheBustingParam}`);
        if (!response.ok) throw new Error(`Failed to fetch ${filename}`);
        const text = await response.text();
        const recipe: RecipeFileType = yaml.load(text) as RecipeFileType;
        recipe.id = filename.replace('.yaml', '');
        return recipe;
    } catch (error) {
        console.error(`Error loading recipe from ${filename}:`, error);
        return null;
    }
};

export const loadAllRecipes = async (): Promise<RecipeFileType[]> => {
    const recipes: RecipeFileType[] = [];

    try {
        const filenames = await loadRecipeFilenames();
        const loadPromises = filenames.map((filename) => loadRecipe(filename));
        const results = await Promise.all(loadPromises);

        for (const recipe of results) {
            if (recipe) recipes.push(recipe);
        }
    } catch (error) {
        console.error('Error loading all recipes:', error);
    }

    return recipes;
};

export const loadCategories = async (): Promise<CategoryType[]> => {
    const filename = 'config/categories.yaml';
    let categories: CategoryType[] = [];

    try {
        const cacheBustingParam = getCacheBustingParam();
        const response = await fetch(`${process.env.PUBLIC_URL}/${filename}?cb=${cacheBustingParam}`);
        if (!response.ok) throw new Error(`Failed to fetch ${filename}`);
        const text = await response.text();
        categories = yaml.load(text) as CategoryType[];
    } catch (error) {
        console.error(`Error loading categories from ${filename}:`, error);
    }

    return categories;
};
