import React, {createContext, useContext, useEffect, useState} from 'react';
import {RecipeFileType, Lang, CategoryType} from '../types/types';
import {loadAllRecipes, loadCategories} from '../util/yaml-loader';

type RecipeContextType = {
    recipes: RecipeFileType[];
    categories: CategoryType[];
    loading: boolean;
    currentRecipeIndex: number;
    setCurrentRecipeIndex: (index: number) => void;
    getNextRecipe: () => RecipeFileType | null;
    getPreviousRecipe: () => RecipeFileType | null;
};

const RecipeContext = createContext<RecipeContextType | undefined>(undefined);

export const RecipeProvider: React.FC<{ children: React.ReactNode; language: Lang }> = ({children, language}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [recipes, setRecipes] = useState<RecipeFileType[]>([]);
    const [currentRecipeIndex, setCurrentRecipeIndex] = useState<number>(0);

    useEffect(() => {
        const fetchRecipes = async () => {
            setLoading(true);
            const categories = await loadCategories();
            const allRecipes = await loadAllRecipes();

            // Identify uncategorized recipes
            const uncategorizedRecipes = allRecipes.filter(
                (recipe) => !recipe.category || !categories.some((category) => category.id === recipe.category)
            );

            if (uncategorizedRecipes.length > 0) {
                // Add an "Uncategorized" category if there are uncategorized recipes
                categories.push({id: 'uncategorized', en: 'Uncategorized', he: 'ללא קטגוריה'});
                uncategorizedRecipes.forEach((r) => (r.category = 'uncategorized'));
            }

            const sortedRecipes = allRecipes.sort((a, b) => {
                const categoryOrderA = categories.findIndex((c) => c.id === a.category);
                const categoryOrderB = categories.findIndex((c) => c.id === b.category);

                if (categoryOrderA === categoryOrderB) {
                    return a[language].title.localeCompare(b[language].title);
                }

                return categoryOrderA - categoryOrderB;
            });

            setCategories(categories);
            setRecipes(sortedRecipes);
            setLoading(false);
        };

        void fetchRecipes();
    }, [language]);

    const getNextRecipe = () => {
        return recipes[currentRecipeIndex + 1] ?? recipes[0];
    };

    const getPreviousRecipe = () => {
        return recipes[currentRecipeIndex - 1] ?? recipes[recipes.length - 1];
    };

    return (
        <RecipeContext.Provider
            value={{
                recipes,
                categories,
                loading,
                currentRecipeIndex,
                setCurrentRecipeIndex,
                getNextRecipe,
                getPreviousRecipe
            }}
        >
            {children}
        </RecipeContext.Provider>
    );
};

export const useRecipeContext = () => {
    const context = useContext(RecipeContext);
    if (!context) {
        throw new Error('useRecipeContext must be used within a RecipeProvider');
    }
    return context;
};
