import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Lang} from "../types/types";
import clsx from "clsx";
import Loader from "./loader";
import {useRecipeContext} from "../context/recipe-context";

type RecipeListProps = {
  language: Lang;
};

const RecipeList: React.FC<RecipeListProps> = ({language}) => {
  const {recipes, categories, loading} = useRecipeContext();

  if (loading) return <Loader className="m-12"/>;

  return (
    <div className={clsx("grid gap-4 w-full py-2 border-b pb-8", language === Lang.HE && "rtl text-right")}>
      <div className="grid gap-4 items-center px-4 md:grid-flow-col md:gap-8">
        <h1 className="text-2xl md:text-3xl font-semibold">{language === Lang.EN ? "Recipe Index" : "תוכן עניינים"}</h1>
        <div id="logo" className="relative z-20 justify-self-end order-first md:order-none overflow-y-visible max-h-2 md:max-h-20">
          <img src="/logo.png" alt="Logo" className={clsx("h-24 md:h-36", language === Lang.EN && "rotate-[45deg]")}/>
        </div>
      </div>

      {categories.map((category) => (
        <div key={category.id} className={clsx("min-w-60 px-2 md:px-4", language === Lang.HE && "rtl text-right")}>
          <div className="grid grid-flow-col grid-cols-[max-content_1fr] gap-x-4 items-center py-1 relative z-10">
            <h1 className="text-lg md:text-xl font-semibold">{category[language]}</h1>
            <div className="flex-grow border-b-2 border-dotted border-gray-300 ml-4 mt-1"></div>
          </div>
          <ul>
            {recipes.filter(r => r.category === category.id).map((recipe) => (
              <li key={recipe.id}>
                <Link to={`/${recipe.id}`}>{recipe[language].title}</Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default RecipeList;
