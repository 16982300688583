import clsx from "clsx";

function Loader({className}: { className?: string }) {
    return (
        <div className={clsx("w-20 grid justify-end ", className)}>
            <div className={clsx("loader")}/>
        </div>
    );
}

export default Loader;